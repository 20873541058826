import React from "react";
import styled from "styled-components";
import Colors from "../helpers/Colors";
import { tablet } from "../helpers/Devices";

const MessageSection = () => {
  return (
    <Wrapper>
      <Content>Youtube Ad Boycott: Download the Ad Blocker</Content>
      <Desc>
        <span style={{ fontWeight: 500 }}>
          Click to download the free, simple ad blocker. You can easily
          whitelist sites that aren't Youtube with just one click.
        </span>
        <br /><br />
        <span style={{ fontWeight: 600 }}>
          The ad blocker may not work on phones and won’t work on the YouTube phone app.
        </span>
      </Desc>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 150px;
  background-color: ${Colors.lightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  @media ${tablet} {
    padding: 15px;
    border-radius: 0;
  }
`;

const Content = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${Colors.black};
  margin-bottom: 15px;
`;

const Desc = styled.div`
  /* color: ${Colors.black}; */
`;

export default MessageSection;
