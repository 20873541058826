import React from "react";
import styled from "styled-components";
import axios from "axios";

import Button from "../components/Button";
import helper from "../assets/helper.png";

import Colors from "../helpers/Colors";
import { tablet, mobileSmall } from "../helpers/Devices";
import Counter from "./Counter";
import { API_URL, ADBLOCKER_URL } from '../helpers/Constants';

const LeftSection = () => {
  
  function increment() {
    axios
      .put(API_URL, {})
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Left>
      <CounterWrapper>
        <Counter />
      </CounterWrapper>

        <p>
          <span className="bold">YouTube’s toxic algorithm is like a rabbit hole of hate,</span>&nbsp;sending viewers spiraling to ever-more extreme content…on purpose!
        </p>

        <p>
          Yet while other social media giants like Twitter and Facebook scramble to stem the spread of disinfo,&nbsp;<span className="bold">YouTube is instead handing over its homepage advertising to Donald Trump - the king of fake news - in the last days of the U.S Election.</span>
      </p>
      
      <p>
      Despite massive public outrage, YouTube refuses to back down. But if&nbsp;<span className="bold">YouTube won’t pull the plug on Trump, you can.</span>
      </p>
      <p>
      If enough of us download a simple, free ad blocker and block ads on YouTube,&nbsp;<span className="bold">we’ll hit right at the heart of the company’s biggest income source.</span>
      </p>
      <p>
      <span className="bold">If enough of us do it, YouTube bosses will have no choice but to sit-up and take notice.&nbsp;</span>Join in:
        </p>
         

        <ButtonBlock>
          <AdBlockButton
            href={ADBLOCKER_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => increment()}
          >
            Download the ad blocker
          </AdBlockButton>
        </ButtonBlock>
        <div>
          To whitelist sites other than Youtube, click the AdBlocker Ultimate
          icon in your browser, and then toggle to&nbsp;
          <span className="bold">"enable on this site".</span>
        </div>
      <Image src={helper} />
    </Left>
  );
};

const Left = styled.div`
  width: 60%;
  padding-right: 30px;
  border-right: 1px solid ${Colors.darkGray};
  p {
    font-size: 18px;
    line-height: 26px;
  }
  .bold {
    font-weight: 700;
  }
  .fas {
    padding: 0 10px;
    font-size: 16px;
  }
  @media ${tablet} {
    width: 100%;
    padding-right: unset;
    border: none;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const CounterWrapper = styled.div`
  display: none;
  @media ${tablet} {
    display: block;
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  @media ${mobileSmall} {
    margin: 0;
  }
`;

const AdBlockButton = styled(Button)`
  margin: 10px 0 25px;
`;

const Image = styled.img`
  height: 500px;
  object-fit: contain;
  margin: 15px auto;
  display: flex;
  @media ${tablet} {
    height: unset;
    width: 100%;
  }
`;

export default LeftSection;
